import React from 'react';
import spinner from './spinner.gif';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function() {
  return (
    <Wrapper>
      <img alt="Loading" width="100px" height="100px" src={spinner} />
    </Wrapper>
  );
}
