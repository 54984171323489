export const colors = {
  red: '#7A1411',
  darkRed: '#5a0f0c',
  black: '#262626',
  white: '#FFFFFF',
  grey: '#d9d9d9',
  darkGrey: '#808080',
  lightGrey: '#e6e6e6',
  backdrop: '#f2f2f2',
  success: '#136c28',
  successBackground: '#e9fbed',
  error: '#831616',
  errorBackground: '#fbe9e9'
};
