import React from 'react';
import { render } from 'react-dom';
import './global.css.js';
import Loadable from 'react-loadable';
import Spinner from 'components/Spinner';
import 'core-js';

// This code allows create react app to spit the code into two bundles
// so that the using the embed code won't load the whole react app.
class Index extends React.Component {
  render() {
    if (window.location.pathname.split('/')[1] === 'embed') {
      return React.createElement(
        Loadable({
          loader: () =>
            import('./EmbeddedPlayer' /* webpackChunkName: "embed_code" */),
          loading: () => <Spinner />
        })
      );
    } else {
      return React.createElement(
        Loadable({
          loader: () => import('./App' /* webpackChunkName: "app" */),
          loading: () => <Spinner />
        })
      );
    }
  }
}

render(<Index />, document.getElementById('root'));
