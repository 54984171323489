import { injectGlobal } from 'styled-components';
import { colors } from 'variables';

injectGlobal`
  @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');

  * {
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
    transition: border-color 0.2s, color 0.2s, background 0.2s, background-color 0.2s;
  }

  body {
    background-color: ${colors.backdrop};
    font-size: 16px;
    font-weight: 500;
    color: ${colors.black};
    position: relative;
    min-height: 100vh;
  }

  a, .link {
    color: ${colors.red};
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  label {
    display: block;
    font-size: 13px;
    font-weight: 600;
    margin-left: 2px;
    margin-top: 8px;
  }

  #nprogress .bar {
    background: ${colors.red} !important;
  }
`;
